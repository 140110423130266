var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"draft-container flex-fill"},[_c('div',{staticClass:"left-panel"},[_c('nav',{attrs:{"aria-label":"Primary navigation"}},[_c('drafting-side-bar',{staticClass:"mobile-hidden tablet-hidden email-sidebar draft-navigation"},[_c('li',{staticClass:"mobile-hidden tablet-hidden nav-item no-list-styling",class:{
            active: _vm.checkComponentActive(_vm.activeLeftComponent, 'ChecklistView'),
          }},[_c('button',{staticClass:"button-icon checklist-icon",attrs:{"aria-label":"Toggle Checklist","aria-expanded":_vm.checkComponentActive(_vm.activeLeftComponent, 'ChecklistView') ? 'true' : 'false',"aria-controls":"checklist-panel"},on:{"click":function($event){return _vm.loadLeftComponent('ChecklistView')}}}),_vm._v(" "),_c('span',{staticClass:"nav-title"},[_vm._v(_vm._s(_vm.checklistName))])]),_vm._v(" "),(_vm.showDescriptionPanel)?_c('li',{staticClass:"nav-item no-list-styling",class:{
            active: _vm.checkComponentActive(_vm.activeLeftComponent, 'DescriptionView'),
          }},[_c('button',{staticClass:"button-icon description-icon",attrs:{"aria-label":"Toggle Description","aria-expanded":_vm.checkComponentActive(_vm.activeLeftComponent, 'DescriptionView') ? 'true' : 'false',"aria-controls":"description-panel"},on:{"click":function($event){return _vm.loadLeftComponent('DescriptionView')}}}),_vm._v(" "),_c('span',{staticClass:"nav-title"},[_vm._v("Description")])]):_vm._e()])],1),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade","mode":"out-in"}},[_c('keep-alive',[(_vm.activeLeftComponent)?_c(_vm.activeLeftComponent,{tag:"component",staticClass:"mobile-hidden tablet-hidden"}):_vm._e()],1)],1)],1),_vm._v(" "),_c('editor-view',{ref:"editorView"}),_vm._v(" "),_c('div',{staticClass:"right-panel"},[_c('transition',{attrs:{"name":"slide-fade-right","mode":"out-in"}},[_c('keep-alive',[(_vm.activeRightComponent)?_c(_vm.activeRightComponent,{tag:"component"}):_vm._e()],1)],1),_vm._v(" "),_c('nav',{attrs:{"aria-label":"Secondary navigation"}},[_c('drafting-side-bar',{staticClass:"email-sidebar draft-navigation"},[_c('li',{staticClass:"nav-item no-list-styling smaller-screen-only",class:{
            active: _vm.checkComponentActive(_vm.activeRightComponent, 'ChecklistView'),
          }},[_c('button',{staticClass:"button-icon checklist-icon",attrs:{"aria-label":"Toggle Checklist","aria-expanded":_vm.checkComponentActive(_vm.activeRightComponent, 'ChecklistView') ? 'true' : 'false',"aria-controls":"checklist-panel"},on:{"click":function($event){return _vm.loadRightComponent('ChecklistView')}}}),_vm._v(" "),_c('span',{staticClass:"nav-title"},[_vm._v(_vm._s(_vm.checklistName))])]),_vm._v(" "),(_vm.showDescriptionPanel)?_c('li',{staticClass:"nav-item no-list-styling smaller-screen-only",class:{
            active: _vm.checkComponentActive(_vm.activeRightComponent, 'DescriptionView'),
          }},[_c('button',{staticClass:"button-icon description-icon",attrs:{"aria-label":"Toggle Description","aria-expanded":_vm.checkComponentActive(_vm.activeRightComponent, 'DescriptionView') ? 'true' : 'false',"aria-controls":"description-panel"},on:{"click":function($event){return _vm.loadRightComponent('DescriptionView')}}}),_vm._v(" "),_c('span',{staticClass:"nav-title"},[_vm._v("Description")])]):_vm._e(),_vm._v(" "),_c('li',{staticClass:"nav-item no-list-styling",class:{
            active: _vm.checkComponentActive(_vm.activeRightComponent, 'ExampleView'),
          }},[_c('button',{staticClass:"button-icon examples-icon",attrs:{"aria-label":"Toggle Examples","aria-expanded":_vm.checkComponentActive(_vm.activeRightComponent, 'ExampleView') ? 'true' : 'false',"aria-controls":"examples-panel"},on:{"click":function($event){return _vm.loadRightComponent('ExampleView')}}}),_vm._v(" "),_c('span',{staticClass:"nav-title"},[_vm._v("Examples")])]),_vm._v(" "),_c('li',{staticClass:"nav-item no-list-styling",class:{
            active: _vm.checkComponentActive(_vm.activeRightComponent, 'CommentView'),
          }},[_c('button',{staticClass:"button-icon comments-icon",attrs:{"aria-label":"Toggle Comments","aria-expanded":_vm.checkComponentActive(_vm.activeRightComponent, 'CommentView') ? 'true' : 'false',"aria-controls":"comments-panel"},on:{"click":function($event){return _vm.loadRightComponent('CommentView')}}}),_vm._v(" "),_c('span',{staticClass:"nav-title"},[_vm._v("Comments")])])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }