import { ref, computed, Ref, ComputedRef } from "vue";
import dayjs from "dayjs";
import { computeWordCount } from "libs/document";
import { DraftSubmissionResponse, SubmissionTag } from "services/submissions";
import { TemplateChecklistItem } from "services/template-checklist-item";
import { useStore } from "shared/composables";

interface SubmissionUIComposable {
  isDisplayingAllTags: Ref<boolean>;
  setIsDisplayingAllTags: (value: boolean) => void;
  draftWordCount: ComputedRef<number>;
  submissionCreatedAt: ComputedRef<string>;
  draftTagsToBeDisplayed: ComputedRef<Array<SubmissionTag>>;
  setSelectedItemIds: (newItemIds: number[]) => void;
}

export function useSubmissionUI(submission: DraftSubmissionResponse): SubmissionUIComposable {
  const store = useStore();

  const isDisplayingAllTags = ref(false);
  const setIsDisplayingAllTags = (value: boolean) => {
    isDisplayingAllTags.value = value;
  };

  const draftWordCount = computed(() => {
    const content = submission.content;
    return computeWordCount(content);
  });

  const submissionCreatedAt = computed(() =>
    dayjs(submission.created_at).format("MMMM D YYYY, h:mm:ss a")
  );

  const selectedItemIds = ref([]);
  const setSelectedItemIds = (newItemIds: number[]) => {
    selectedItemIds.value = newItemIds;
  };

  const draftTagsToBeDisplayed = computed(() => {
    const items = store.state.template.checklistItems;
    const currentDraftTags = submission.tags;

    const tagsWithColor = currentDraftTags.map((tag) => {
      const checklistItem = items.find(
        (item: TemplateChecklistItem) => item.id === tag.template_checklist_item_id
      );
      return { ...tag, color: checklistItem.color };
    });

    if (!isDisplayingAllTags.value && selectedItemIds.value.length === 0) {
      return [];
    }

    if (isDisplayingAllTags.value && selectedItemIds.value.length === 0) {
      return tagsWithColor;
    } else {
      const foundTags = tagsWithColor.filter((tag) =>
        selectedItemIds.value.includes(tag.template_checklist_item_id)
      );
      return foundTags;
    }
  });

  return {
    isDisplayingAllTags,
    setIsDisplayingAllTags,
    draftWordCount,
    submissionCreatedAt,
    draftTagsToBeDisplayed,
    setSelectedItemIds,
  };
}
