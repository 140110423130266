<template>
  <div class="about external-page">
    <header class="header external" :class="{ 'dark': darkHeader }">
      <external-header></external-header>
    </header>
    <main id="main" class="container about-page">
      <section class="hero external-section dark" @mousemove="handleMouseMove" @mouseleave="handleMouseLeave">
        <div class="dark-container">
          <hero-header :shape-position="shapePosition">
            <template #title>About Us</template>
          </hero-header>
          <img src="/static/images/landing-page/scribbles/underline-scribble.svg" alt="" class="underline" />
          <div class="breadcrumbs">
            <router-link :to="{ name: 'Landing' }" class="button-link">Home</router-link>
            <img src="/static/images/icons/arrow-white.png" alt="" class="arrow" />
            <a class="button-link">About Us</a>
          </div>
        </div>
      </section>
      <div class="external-section our-story">
        <div class="our-story-text">
          <h2 class="new-serif h2 display">Lettersmith's Story</h2>
          <p class="text-med">
            Writing professional emails, formatting your resume, and even sending a cold email to a potential employer takes practice. 
            Born from Julie Hui’s dissertation, Lettersmith works to support students with professional communications. In her observations, 
            she noticed that students working on real-world projects had difficulty emailing clients. Students did not know what language to use 
            and often delayed sending emails, which delayed their project progress. Through design-based research, she developed Lettersmith to reduce 
            the anxiety around getting started with writing and scaffold the process of written professional communication. Since then, 
            Lettersmith has been expanded to support a wide range of writing, like cover letters, college essays, and engineering reports.
          </p>
        </div>
        <div class="our-video">
          <iframe
            title="Lettersmith overview video"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/9IDVnvOd8cY"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <div class="text-med">
            <p>
              Lettersmith helps its writers build skills to communicate more effectively with
              clients, employers, and fellow professionals. Writers can:
            </p>
            <br/>
            <div class="card-section">
              <div class="card pink">
                <img
                  src="/static/images/landing-page/tag-feature-icon-grey.svg"
                  alt="Tags Feature Icon"
                  class="feature-icon"
                />
                <div class="new-serif">Draft & Tag</div>
                Follow along with a checklist of best practices
              </div>
              <div class="card yellow">
                <img
                  src="/static/images/landing-page/example-feature-icon-grey.svg"
                  alt="Example Feature Icon"
                  class="feature-icon"
                />
                <div class="new-serif">Explore Examples</div>
                See examples of communication from experts and peers
              </div>
              <div class="card orange">
                <img
                  src="/static/images/landing-page/feedback-feature-icon-grey.svg"
                  alt="Feedback Feature Icon"
                  class="feature-icon"
                />
                <div class="new-serif">Give & Receive Feedback</div>
                Give and receive feedback on their writing from experts and peers
              </div>
            </div>
            <br />
            <p>
              This writing tool builds on the collaborative expertise of a writing community to
              produce guidance that is specific to one’s individual communication goals. Better emails
              and letters can mean stronger professional relationships, better project planning and
              reflection, and more confidence in your communication.
            </p>
          </div>
        </div>
        <div class="external-subsection our-partners">
          <h3 class="new-serif h3">Our Partners</h3>
          <h4 class="new-serif h4">University of Michigan</h4>
          <div class="card-section">
            <div class="card">
              <div class="u-of-m"></div>
              <a href="https://www.si.umich.edu/" target="_blank">School of Information</a>
            </div>
            <div class="card">
              <div class="u-of-m"></div>
              <a href="https://sph.umich.edu/" target="_blank">School of Public Health</a>
            </div>
            <div class="card">
              <div class="u-of-m"></div>
              <a href="https://lsa.umich.edu/english" target="_blank">Department of English, Language, and Literature</a>
            </div>
            <div class="card">
              <div class="u-of-m"></div>
              <a href="https://cfe.umich.edu/" target="_blank">Center for Entrepreneurship</a>
            </div>
            <div class="card">
              <div class="u-of-m"></div>
              <a href="https://careercenter.umich.edu/" target="_blank">Career Center</a>
            </div>
            <div class="card">
              <div class="u-of-m"></div>
              <a href="https://techcom.engin.umich.edu/" target="_blank">Engineering Program in Technical Communication</a>
            </div>
          </div>
        </div>
      </div>
      <div class="external-section our-team-2 dark" data-aos="fade-up" data-aos-duration="500">
        <div class="dark-container">
          <h2 class="new-serif h2 display">Our Team</h2>
          <p class="text-med text">
            Our team is made up of designers, developers, behavioral scientists, researchers, and
            students who work to ensure a pleasant and meaningful experience for Lettersmith
            admins and writers.
          </p>
          <div class="julie-cai external-subsection">
            <h3 class="new-serif h3">Julie Hui & the Center for Academic Innovation</h3>
            <div class="image-container">
              <img
                src="/static/images/team-images/julie-hui.png"
                alt=""
                class="julie-image"
              />
              <div class="um-shape">
                <img
                  src="/static/images/landing-page/m-block.png"
                  alt="Template icon"
                  class="block-m"
                />
                <img
                  src="/static/images/landing-page/scalloped-shape-blue.svg"
                  alt="Template icon"
                />
              </div>
            </div>
            <p class="text-med text">
              Julie Hui is an Assistant Professor at the University of Michigan's School of Information and Director of the Socio-Technical Equity in Practice Lab. 
              Hui studies and develops socio-technical systems that support workforce development. 
              Prior to her arrival at U-M, Hui completed her Ph.D. in Mechanical Engineering at Northwestern University's Segal Design Institute, 
              where she was funded by the NSF Graduate Research Program Fellowship. 
              She previously interned at Microsoft and Facebook as an HCI/UX Researcher. 
              She received her B.S. in Physics with a Minor in Mechanical Engineering from MIT in 2011.
            </p>
            <br>
            <p class="text-med text">
              The University of Michigan Center for Academic Innovation is committed to a future in which education connects and 
              empowers all learners to reach their full potential throughout their lives. 
              We collaborate across campus and around the world to create equitable, 
              lifelong educational opportunities for students and learners everywhere. 
              Our work spans curricular innovation, tools for student success and equity, 
              educational research and analytics, and academic innovation policy.
            </p>
            <br>
            <p class="text-med text">
              Our Educational Technology team consists of talented engineers, UX designers, 
              and behavioral scientists who work to create accessible, engaging, equitable experiences for students and instructors alike.
            </p>
            <br>
            <a class="button-secondary big-button white-border" href="/about">Learn More About Educational Technology</a>
          </div>
          <h3 class="new-serif h3 external-subsection">Current Contributors</h3>
        </div>
        <current-contributors class="current-contributors-contatiner"></current-contributors>
        <div class="dark-container">
          <div class="external-subsection">
            <h3 class="new-serif h3">Former Contributors</h3>
            <former-contributors class="former-contributors-contatiner"></former-contributors>
          </div>
        </div>
      </div>
      <div class="external-section FAQ" data-aos="fade-up" data-aos-duration="500">
        <h2 id="faq-header" class="new-serif h2 display">FAQs</h2>
        <p>Learn more about Lettersmith!</p>
        <div aria-labelledby="faq-header" id="accordionGroup" class="external-subsection">
          <question-container>
            <template #question>
              <b>Is Lettersmith free?</b>
            </template>
            <template #icon>
              <img
                src="/static/images/landing-page/pink-plus.svg"
                alt="Tags Feature Icon"
                class="plus-icon"
              />
            </template>
            <template #answer>
              Yes! Anyone can use Lettersmith at no cost. Reach
              out to <a href="mailto:lettersmith-support@umich.edu">our team</a> for a walk-through.
            </template>
          </question-container>
          <question-container>
            <template #question>
              <b>How can I start using Lettersmith?</b>
            </template>
            <template #icon>
              <img
                src="/static/images/landing-page/yellow-plus.svg"
                alt="Tags Feature Icon"
                class="plus-icon"
              />
            </template>
            <template #answer>
              You can sign-in to Lettersmith and start using it
              right away. We also provide set up step-by-step instructions. If you’d like a live walk
              through of the tool for your needs, please contact
              <a href="mailto:lettersmith-support@umich.edu">lettersmith-support@umich.edu</a>.
            </template>
          </question-container>
          <question-container>
            <template #question>
              <b>Are there existing writing templates I can adopt for class?</b>
            </template>
            <template #icon>
              <img
                src="/static/images/landing-page/orange-plus.svg"
                alt="Tags Feature Icon"
                class="plus-icon"
              />
            </template>
            <template #answer>
              Currently, we have three public templates: Cold
              Email, Cover Letter, and Follow Up. We are in the process of creating more, so if you
              have any suggestions, please let us know!
            </template>
          </question-container>
        </div>
      </div>
    </main>
    <section class="sub-footer" aria-labelledby="subfooter-heading">
      <h2 class="new-serif h2 display" id="subfooter-heading">Contact Us</h2>
      <div class="text-med">Please contact us if you have questions or would like to learn more about using Lettersmith</div>
      <router-link custom :to="{ name: 'Signup' }" v-slot="{ navigate }">
        <button class="button-dark big-button" @click="navigate">Email Us</button>
      </router-link>
      <div class="text-med join-email-list">Join our email list to stay up to date with Lettersmith's newest features</div>
      <a href="https://mailchi.mp/umich/t9kzbo9scr" target="_blank" class="button-dark big-button">
        Join Email List
      </a>
    </section>
    <et-footer></et-footer>
  </div>
</template>

<script>
import CurrentContributors from './CurrentContributors.vue';
import FormerContributors from './FormerContributors.vue';
import HeroHeader from './HeroHeader.vue';
import QuestionContainer from './QuestionContainer.vue';

export default {
  components: { CurrentContributors, FormerContributors, HeroHeader, QuestionContainer },
  data: () => ({
    firstNewItemX: 0,
    lastNewItemX: 600,
    newItemsX: 0,
    darkHeader: true,
    shapePosition: {
      transform: '',
    },
  }),

  computed: {
    hasLeftOverflow() {
      return this.newItemsX - this.firstNewItemX > 0;
    },

    hasRightOverflow() {
      const newItemAvailableLeftMargin = 600; // pixels
      return this.lastNewItemX - this.newItemsX >= newItemAvailableLeftMargin;
    },
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },

  methods: {
    handleMouseMove(event) {
      this.shapePosition.transform = 'translate(' + event.clientX / '100' + '%,' + event.clientY / '100' + '%) rotate('  + event.clientY / '100' + 'deg';
    },

    handleMouseLeave() {
      this.shapePosition.transform = 'translate(0, 0) rotate(0)';
    },

    handleScroll() {
      const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;

      if (scrollPosition > 100) {
        this.darkHeader = false;
      } else {
        this.darkHeader = true;
      }
    },

    onScroll() {
      this.updateNewItemsPositions();
    },

    updateNewItemsPositions() {
      this.newItemsX = this.$refs.newItemsContainer.getBoundingClientRect().x;
      this.firstNewItemX = this.$refs.firstNewItem.getBoundingClientRect().x;
      this.lastNewItemX = this.$refs.lastNewItem.getBoundingClientRect().x;
    },
  },
};
</script>
