import { getCurrentInstance } from "vue";
import { Store } from "vuex";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useStore(): Store<any> {
  const vm = getCurrentInstance();
  if (!vm) throw new Error("must be called in setup");

  return vm.proxy.$store;
}
