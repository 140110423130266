<template>
  <div class="tag-view-toggle">
    <label id="tag-view-label" for="tag-view-switch">Tag View:</label>
    <button
      id="tag-view-switch"
      type="button"
      role="switch"
      aria-labelledby="tag-view-label"
      :aria-checked="checked + ''"
      @click="toggleSwitch"
    >
      <span class="marker" role="presentation"></span>
      <span class="status" v-if="checked" aria-hidden="true">On</span>
      <span class="status" v-if="!checked" aria-hidden="true">Off</span>
    </button>
  </div>
</template>

<script>
import { defineComponent, toRefs } from 'vue';

export default defineComponent({
  props: {
    checked: {
      type: Boolean,
      required: true,
    }
  },
  setup(props, { emit }) {
    const { checked } = toRefs(props);
    const toggleSwitch = () => emit("toggle", !checked.value);

    return {
      toggleSwitch,
    }
  }
})
</script>

<style scoped>
label {
  display: inline;
  margin-right: 0.5rem;
}
button {
  position: relative;
  width: 50px;
  height: 22px;
  font-size: 10px;
  font-weight: 600;
  line-height: 22px;
  color: white;
  background-color: #757575;
  border-radius: 11px;
  text-align: right;
}
button[aria-checked=true] {
  background-color: #3972DC;
  text-align: left;
}
button:focus {
  outline: #007aff solid 2px;
  outline-offset: 2px;
}

.status {
  margin-right: 12px;
}
button[aria-checked=true] .status {
  margin-right: 0;
  margin-left: 12px;
}

.marker {
  display: block;
  position: absolute;
  top: 3px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: white;
  transform: translate(4px);
  transition: transform 0.05s ease-in;
}
button[aria-checked=true] .marker {
  transform: translate(30px);
}
</style>
