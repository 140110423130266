import AOS from "aos";
import Vue from "vue";
import { provide} from "vue";
import VueSecureHTML from "vue-html-secure";

import { abilitiesPlugin, Can } from "@casl/vue";

import App from "./App.vue";
import "./bootstrap";
import router from "./router";
import store, { StoreSymbol } from "./store";
import ability from "core/abilities";
import focus from "./shared/directives/focus";
import focusTrap from "./shared/directives/focusTrap";

import "../sass/app.scss";
import "aos/dist/aos.css";

Vue.use(abilitiesPlugin, ability);
Vue.use(VueSecureHTML);
Vue.component("Can", Can);
Vue.directive("focus", focus);
Vue.directive("focusTrap", focusTrap);

AOS.init();

new Vue({
  setup() {
    provide(StoreSymbol, store);
  },
  el: "#app",
  store,
  router,
  render: (h) => h(App),
});
