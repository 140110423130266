<template>
  <div class="question-container">
    <button
      @click="toggleQuestion" 
      class="question text-med" 
      :class="{ open: questionOpen }" 
      role="button"
      :aria-expanded="questionOpen ? 'true' : 'false'"
      id="accordion1id"
      aria-controls="question1"
    >
      <span class="new-serif">Q</span> 
      <slot name="question"> </slot>
      <slot name="icon"> </slot>
    </button>
    <div 
      v-if="questionOpen" 
      class="answer text-med" 
      id="question1"
      role="region" 
      aria-labelledby="accordion1id"
    >
      <slot name="answer"> </slot>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    questionOpen: false,
  }),

  methods: {
    toggleQuestion() {
      this.questionOpen = !this.questionOpen;
    },
  },
};
</script>
