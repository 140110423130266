<template>
  <div class="settings">
    <div class="setting">
      <h1>Canvas Course Association</h1>
      <div v-if="association === null">
        No Canvas course associated:
        <button class="button-secondary" @click="showSelectCourseModal = true">Add Canvas Association</button>
      </div>
      <div v-if="association">
        Currently associated with: {{ association.name }}.
        <button class="button-secondary" @click="onRemoveAssociation">Remove Canvas Association</button>
      </div>
      <select-course-modal
        v-if="showSelectCourseModal"
        :all-courses="courses"
        :association-service="CollectionAPI"
        :asset-id="collection.id"
        @close="showSelectCourseModal = false"
        @new-association="onNewAssociation"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, toRefs } from "vue";
import SelectCourseModal from "shared/components/canvas/SelectCourseModal.vue";
import CanvasAPI from "~/services/canvas";
import CollectionAPI from "~/services/collections";
import { removeCanvasAssociation } from "shared/composables/canvasAssociation";

export default defineComponent({
  components: {
    SelectCourseModal,
  },
  props: {
    collection: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { collection } = toRefs(props);

    const courses = ref([]);
    const showSelectCourseModal = ref(false);
    const association = ref(null);

    CanvasAPI.courses().then((response) => {
      courses.value = response.data;
    });

    CollectionAPI.listCanvasAssociations(collection.value.id).then((response) => {
      if (response.data) {
        association.value = response.data[0] || null;
      }
    });

    const onNewAssociation = (newAssociation) => {
      association.value = newAssociation;
      showSelectCourseModal.value = false;
    };
    const onRemoveAssociation = async () => {
      const removed = await removeCanvasAssociation(
        collection.value.id,
        association.value.id,
        CollectionAPI
      );
      if (removed) {
        association.value = null;
      }
    };

    return {
      association,
      courses,
      onNewAssociation,
      onRemoveAssociation,
      showSelectCourseModal,
      CollectionAPI,
    };
  },
});
</script>
