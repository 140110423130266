<template>
  <div class="hero-header">
    <h1 class="new-serif h1 display">
      <slot name="title"></slot>
    </h1>
    <img :style="shapePosition" src="/static/images/landing-page/scribbles/zigzag-scribble.svg" alt="" class="zigzag scribble" />
    <img :style="shapePosition" src="/static/images/landing-page/scribbles/star-scribble.svg" alt="" class="star scribble" />
    <img :style="shapePosition" src="/static/images/landing-page/scribbles/squiggle-scribble.svg" alt="" class="squiggle scribble" />
    <img :style="shapePosition" src="/static/images/landing-page/scribbles/dot-scribble.svg" alt="" class="dot scribble" />
  </div>
</template>

<script>
export default {
  props: {
    shapePosition: {
      type: String,
      default: "",
    },
  },
};
</script>