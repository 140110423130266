<template>
  <modal :show-close="true" :visible="true" @close="$emit('close')">
    <template #content>
      <h2 class="text-med">Associate with Canvas Course</h2>
      <div class="modal-form-field">
        <label for="select-course" class="form-label bold">Select a course found in Canvas to associate this template with:</label>
        <select v-model="selectedCourse" id="select-course">
          <option
            v-for="course in allCourses"
            :key="course.id"
            :value="course"
            :disabled="alreadyAssociated(course)"
          >
            {{ course.name }}
          </option>
        </select>
      </div>
    </template>

    <template #footer>
      <button class="button" @click="setAssociation(selectedCourse)">Associate with Canvas Course</button>
    </template>
  </modal>
</template>

<script>
import { defineComponent, ref } from "vue";
import { addCanvasAssociation } from "../../composables/canvasAssociation";
import Modal from "../Modal.vue";

export default defineComponent({
  components: {
    Modal,
  },
  props: {
    activeAssociations: {
      type: Array,
      default: () => [],
    },
    allCourses: {
      type: Array,
      required: true,
    },
    associationService: {
      type: Object,
      required: true,
    },
    assetId: {
      type: Number,
      required: true,
    },
  },
  emits: ["new-association", "close"],
  setup(props, context) {
    const selectedCourse = ref(props.activeAssociations[0] || null);

    const setAssociation = async (course) => {
      const newAssociation = await addCanvasAssociation(
        props.assetId,
        course,
        props.associationService
      );
      context.emit("new-association", newAssociation);
    };
    const alreadyAssociated = (course) => {
      return props.activeAssociations.includes(course);
    };

    return {
      selectedCourse,
      setAssociation,
      alreadyAssociated,
    };
  },
});
</script>
