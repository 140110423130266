import store from "~/store";
import router from "../../router";

import Account from "./views/Account.vue";
import BackendAuthRedirect from "./views/BackendAuthRedirect.vue";
import Login from "./views/Login.vue";
import Signup from "./views/Signup.vue";
import SignupInvited from "./views/SignupInvited.vue";
import LoginForgotPassword from "./views/LoginForgotPassword.vue";
import LoginResetPassword from "./views/LoginResetPassword.vue";
import SignupConfirm from "./views/SignupConfirm.vue";
import SignupCheckInbox from "./views/SignupCheckInbox.vue";
import TemplateLinkRedirect from "./views/TemplateLinkRedirect.vue"
import LoginSelectViewingMode from "./views/LoginSelectViewingMode.vue";

const AuthModule = () => import("./AuthModule.vue");

const redirectIfAuth = (to, from, next) => {
  if (store.state.loggedIn) {
    let destination = { name: "Dashboard" };

    if (to.params.templateId) {
      destination = {
        name: "Template",
        params: {
          templateId: to.params.templateId
        }
      };
    } else if (to.params.collectionId) {
      destination = {
        name: "Collection",
        params: {
          collectionId: to.params.collectionId
        }
      };
    } else if (to.query.next) {
      destination = {
        path: to.query.next
      };
    }
    
    next(destination);
  } else {
    next();
  }
};

const simpleAuth = (to, from, next) => {
  if (!store.state.loggedIn) {
    next({
      name: "Login",
      query: { next: to.fullPath },
    });
  } else if (store.state && store.state.user.is_writer == null) {
    next({
      name: "LoginSelectViewingMode",
      query: { next: to.fullPath },
    });
  } else {
    next();
  }
};

export const routes = [
  {
    path: "/account",
    name: "Account",
    component: Account,
    beforeEnter: simpleAuth,
  },
  {
    path: "/login/viewing-mode/",
    name: "LoginSelectViewingMode",
    component: LoginSelectViewingMode,
  },
  {
    path: "/login/viewing-mode/template/:templateId",
    name: "ViewingModeWithTemplate",
    component: LoginSelectViewingMode,
    props: true,
  },
  {
    path: "/login/viewing-mode/collection/:collectionId",
    name: "ViewingModeWithCollection",
    component: LoginSelectViewingMode,
    props: true,
  },
  // NOTE: Sync the auth routes with urls.py
  {
    path: "/auth",
    component: AuthModule,
    beforeEnter: redirectIfAuth,
    children: [
      {
        path: "login",
        name: "Login",
        component: Login,
      },
      {
        path: "login/template/:templateId",
        name: "LoginWithTemplate",
        component: Login,
        props: true,
      },
      {
        path: "login/collection/:collectionId",
        name: "LoginWithCollection",
        component: Login,
        props: true,
      },
      {
        path: "forgot-password",
        name: "LoginForgotPassword",
        component: LoginForgotPassword,
        props: true,
      },
      {
        path: "reset-password/:email/:token",
        name: "LoginResetPassword",
        component: LoginResetPassword,
        props: true,
      },
      {
        path: "signup",
        name: "Signup",
        component: Signup,
      },
      {
        path: "register/check-email/",
        name: "SignupCheckEmail",
        component: SignupCheckInbox,
        props: true,
      },
      {
        path: "register/confirm/:uidb64/:token",
        name: "SignupConfirm",
        component: SignupConfirm,
        props: true,
      },
      {
        path: "signup/template/:templateId",
        name: "SignupWithTemplate",
        component: Signup,
        props: true,
      },
      {
        path: "signup/collection/:collectionId",
        name: "SignupWithCollection",
        component: Signup,
        props: true,
      },
      {
        path: "signup/:token",
        name: "SignupInvited",
        component: SignupInvited,
        props: true,
      },
    ],
  },
  {
    path: "/auth/success",
    name: "LoginSuccess",
    component: BackendAuthRedirect,
    props: {
      message: "Completing login…",
    },
  },
  {
    path: "/auth/redirect",
    name: "BackendAuthRedirect",
    component: BackendAuthRedirect,
    props: true,
  },
  {
    path: "/join-template-link/:code",
    name: "TemplateLinkRedirect",
    component: TemplateLinkRedirect,
    beforeEnter: simpleAuth,
    props: true,
  },
];
