<template>
  <header class="header">
    <div class="header__inner">
      <slot></slot>
      <div>
        <router-link :to="{ name: loggedIn ? 'Dashboard' : 'Landing' }" class="logo-link">
          <img
            src="/static/images/logos/lettersmith-logo-new.svg"
            alt="Lettersmith"
            class="lettersmith-logo"
          />
          <img
            src="/static/images/logos/lettersmith-mark.svg"
            alt="Lettersmith"
            class="lettersmith-logo-mark"
          />
        </router-link>
      </div>
      <div class="text-small sans-serif" v-if="loggedIn">
        <user-menu></user-menu>

      </div>
      <router-link class="button-secondary" v-if="!loggedIn && isInGuestFeedback" :to="{ name: 'Login' }">
        Login
      </router-link>
    </div>
  </header>
</template>

<script>
import { mapState } from "vuex";

import UserMenu from "core/UserMenu.vue";

export default {
  components: {
    UserMenu,
  },

  data() {
    return {
      forceClose: false,
      showDropdown: false,
    };
  },

  computed: {
    ...mapState("drafting", { isInGuestFeedback: "isInGuestFeedback" }),
    ...mapState(["loggedIn"]),
  },
};
</script>
