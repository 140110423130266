<template>
  <div ref="landing" class="landing external-page">
    <div class="background" aria-hidden="true">
      <img src="/static/images/landing-page/hexagon2.svg" alt="" class="hexagon1" />
      <img src="/static/images/landing-page/hexagon3.svg" alt="" class="hexagon2" />
    </div>
    <header class="header external" :class="{ 'dark': darkHeader }" ref="header">
      <external-header></external-header>
    </header>
    <main id="main" class="container">
      <section class="hero external-section dark" @mousemove="handleMouseMove" @mouseleave="handleMouseLeave">
        <div class="dark-container">
          <hero-header :shape-position="shapePosition">
            <template #title>Write With Purpose.</template>
          </hero-header>
          <img src="/static/images/landing-page/scribbles/underline-scribble.svg" alt="" class="underline" />
          <p class="text-med tagline">
            Lettersmith uses the collaborative expertise of a writing community to provide personalized guidance 
            tailored to meet students' needs and help them achieve their writing goals.
          </p>
          <div class="explore">
            <router-link custom :to="{ name: 'Signup' }" v-slot="{ navigate }">
              <button class="button big-button white-border" @click="navigate">Get Started</button>
            </router-link>
            <a class="button-secondary big-button white-border" href="/about">Learn More</a>
          </div>
          <div class="card-section text-med">
            <div class="card draft" @click="scrollToTags">
              <h2>Draft & Tag</h2>
              <draft-doodle></draft-doodle>
              <p>Create a checklist of best practices</p>
            </div>
            <div class="card example" @click="scrollToExamples">
              <h2>Explore Examples</h2>
              <example-doodle></example-doodle>
              <p>See examples of communication</p>
            </div>
            <div class="card feedback" @click="scrollToFeedback">
              <h2>Give & Receive Feedback</h2>
              <feedback-doodle></feedback-doodle>
              <p>Get feedback on your writing</p>
            </div>
          </div>
        </div>
      </section>
      <section class="feature external-section">
        <h2 class="new-serif h2 display">Features</h2>
        <div class="feature-subtitle text-med">
          Lettersmith is equipped with customizable features designed to help people improve their writing.
        </div>
        <div class="external-subsection feature-section">
          <div class="scroll-to" ref="tags"></div>
          <div class="feature-header flex vc">
            <h3 class="new-serif h3">Draft & Tag With Checklist Items</h3>
            <img
              src="/static/images/landing-page/tag-feature-icon.svg"
              alt="Tags Feature Icon"
              class="feature-icon"
            />
          </div>
          <p class="text-med">
            Create checklists with criteria for students to follow, giving them the tools they need to write successful research papers, resumes, and professional emails.
          </p>
          <feature-video featureInteraction="/static/images/tag-interaction.mp4"></feature-video>
        </div>
        <div class="external-subsection feature-section">
          <div class="scroll-to" ref="examples"></div>
          <div class="feature-header flex vc">
            <h3 class="new-serif h3">Explore Examples</h3>
            <img
              src="/static/images/landing-page/example-feature-icon.svg"
              alt="Example Feature Icon"
              class="feature-icon"
            />
          </div>
          <p class="text-med">
            Add examples and sample writing to highlight essential elements of the writing process.
          </p>
          <feature-video featureInteraction="/static/images/examples-interaction.mp4"></feature-video>
        </div>
        <div class="external-subsection feature-section">
          <div class="scroll-to" ref="feedback"></div>
          <div class="feature-header flex vc">
            <h3 class="new-serif h3">Give & Receive Feedback</h3>
            <img
              src="/static/images/landing-page/feedback-feature-icon.svg"
              alt="Feedback Feature Icon"
              class="feature-icon"
            />
          </div>
          <p class="text-med">
            Provide feedback on student work or give students access to give feedback on their peers' work.
          </p>
          <feature-video featureInteraction="/static/images/feedback-interaction.mp4"></feature-video>
        </div>
        <div class="external-subsection feature-section">
          <div class="feature-header flex vc">
            <h3 class="new-serif h3">Export & Send Work </h3>
          </div>
          <p class="text-med">
            Allow students to export their work to Microsoft Word or email their work directly from Lettersmith.
          </p>
        </div>
      </section>
      <section class="workspace external-section dark" data-aos="fade-up">
        <div class="dark-container">
          <h2 class="new-serif h2 display">Content Formation</h2>
          <div class="external-subsection">
            <h3 class="new-serif h3">Templates</h3>
            <p class="content-subheader">
              Lettersmith supports professional and academic writing by allowing individuals to create
              and share custom templates. Invite students to view a template with a code or a link, making assigning tasks easy and seamless.
              Build personalized templates or use our pre-made ones to help students with:
            </p>
          </div>
          <div class="info-row">
            <div class="info-item orange">
              <div class="info-item-container">
                <div class="info-header">
                  <h4 class="info-name">Cover Letters</h4>
                  <img
                    src="/static/images/landing-page/starburst-shape.svg"
                    alt="Template icon"
                    class="info-icon"
                  />
                </div>
                <p class="info-content">
                  Applying for a new job? Introduce and describe yourself in a way that resonates with the job
                  posting.
                </p>
              </div>
            </div>
            <div class="info-item green">
              <div class="info-item-container">
                <div class="info-header">
                  <h4 class="info-name">Cold Emails</h4>
                  <img
                    src="/static/images/landing-page/bulbous-shape.svg"
                    alt="Template icon"
                    class="info-icon"
                  />
                </div>
                <p class="info-content">
                  Reaching out to someone new? Write an email that builds trust and motivates them to
                  respond.
                </p>
              </div>
            </div>
            <div class="info-item pink">
              <div class="info-item-container">
                <div class="info-header">
                  <h4 class="info-name">Follow Ups</h4>
                  <img
                    src="/static/images/landing-page/eight-pointed-star-shape.svg"
                    alt="Template icon"
                    class="info-icon"
                  />
                </div>
                <p class="info-content">
                  Circling back with a peer? Get guidance on writing polite follow-ups that nudge a response. 
                </p>
              </div>
            </div>
          </div>
          <div class="info-row">
            <div class="info-item teal">
              <div class="info-item-container">
                <div class="info-header">
                  <h4 class="info-name">Argumentative Essays</h4>
                  <img
                    src="/static/images/landing-page/scalloped-shape.svg"
                    alt="Template icon"
                    class="info-icon"
                  />
                </div>
                <p class="info-content">
                  Interested in learning how to persuade a reader to adopt your point of view? 
                  Do so with this reasoned, logical analysis.
                </p>
              </div>
            </div>
            <div class="info-item yellow">
              <div class="info-item-container">
                <div class="info-header">
                  <h4 class="info-name">Resumes</h4>
                  <img
                    src="/static/images/landing-page/twinkle-shape.svg"
                    alt="Template icon"
                    class="info-icon"
                  />
                </div>
                <p class="info-content">
                  Summarize your education, work experience, skills, 
                  and achievements to translate to potential employers.
                </p>
              </div>
            </div>
            <div class="info-item purple">
              <div class="info-item-container">
                <div class="info-header">
                  <h4 class="info-name">Research Paper Outlines</h4>
                  <img
                    src="/static/images/landing-page/wavy-shape.svg"
                    alt="Template icon"
                    class="info-icon"
                  />
                </div>
                <p class="info-content">
                  Piecing together a paper? Accurately struture your topic's research and analyses. 
                </p>
              </div>
            </div>
          </div>
          <div class="info-row">
            <div class="info-item customize">
              <div class="info-item-container">
                <div class="info-header">
                  <h4 class="info-name">Customize Your Own</h4>
                  <svg class="info-icon" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="28.02" height="25" viewBox="0 0 28.02 25">
                    <path id="Path_1" data-name="Path 1" class="cls-1" d="M28,12.5c0,2.37-2.14,4.19-3.24,6.11S23,23.28,21,24.43s-4.59.27-7,.27-5.06.8-7-.27-2.58-3.85-3.77-5.82S0,14.87,0,12.5,2.13,8.31,3.23,6.39,5,1.71,7,.57,11.6.3,14,.3s5.06-.8,7,.27,2.59,3.85,3.77,5.82S28,10.13,28,12.5Z"/>
                  </svg>
                </div>
                <div class="info-content text-med">
                  Do you have an idea for a custom writing template that could help your students or
                  others write to a specific audience or objective? Design a custom template, add tags,
                  include writing examples, and share your template with others.
                </div>
              </div>
            </div>
          </div>
          <div class="external-subsection">
            <h3 class="new-serif h3">Collections</h3>
            <p class="content-subheader">
              Group templates based on course or type of content, and share with your students.
            </p>
          </div>
        </div>
      </section>
      <section class="testimonials external-section" data-aos="fade-up">
        <h2 class="new-serif h2 display">Testimonials</h2>
        <div class="feature-subtitle text-med">
          Hear what advisors, faculty, and students have to say about Lettersmith:
        </div>
        <div class="testimonials-container external-subsection">
          <img
            src="/static/images/landing-page/testimonial.svg"
            alt="Template icon"
            class="testimonial-icon"
          />
          <div class="new-serif display left-quote">“</div>
          <p>
            One of the things that I found really useful was that I could look at what students were tagging and 
            try to kind of understand how each of them was grasping those individual concepts about writing. 
            It really helped me get into kind of thinking on a sort of one-on-one basis with some of 
            the students… I could look at [their writing] and say all right, there's a fundamental misconception.
          </p>
          <div class="new-serif display right-quote">”</div>
        </div>
        <div class="testimonials-container external-subsection">
          <img
            src="/static/images/landing-page/testimonial-2.svg"
            alt="Template icon"
            class="testimonial-icon"
          />
          <div class="new-serif display left-quote">“</div>
          <p>
            Using Lettersmith has definitely improved my approach to teaching cover letters because it has 
            forced me to really think about all of these different components and define them in really clear terms.
          </p>
          <div class="new-serif display right-quote">”</div>
        </div>
      </section>
      <section class="contact external-section dark" data-aos="fade-up">
        <div class="dark-container">
          <h2 class="new-serif h2 display text-center">Contact Us</h2>
          <div class="feature-subtitle text-med text-center">
            Whether you would like to request a demo, have interest in learning more about Lettersmith, 
            or would like to provide general feedback for our tool, please email us!      
          </div>
          <div class="external-subsection">
            <a href="mailto:lettersmith-support@umich.edu?subject=Lettersmith Demo Request&body=I'd like to schedule a demo of Lettersmith..." class="shape-link">
              <div class="text new-serif display">Request A Demo</div>
              <wavy-shape-link></wavy-shape-link>
            </a>
            <a href="mailto:lettersmith-support@umich.edu" class="shape-link email-us">
              <div class="text new-serif display">Email Us</div>
              <bulbous-shape-link></bulbous-shape-link>
            </a>
            <a href="mailto:lettersmith-support@umich.edu?subject=Lettersmith Feedback&body=I'd like to provide feedback for Lettersmith..." class="shape-link">
              <div class="text new-serif display">Send Us Feedback</div>
              <scalloped-shape-link></scalloped-shape-link>
            </a>
          </div>
        </div>
      </section>
    </main>
    <section class="sub-footer" aria-labelledby="subfooter-heading">
      <h2 class="new-serif h2 display" id="subfooter-heading">Create A Free Account</h2>
      <di class="subtitle text-med">Easily create your own writing templates or join others:</di>
      <router-link custom :to="{ name: 'Signup' }" v-slot="{ navigate }">
        <button class="button-dark big-button" @click="navigate">Get Started</button>
      </router-link>
    </section>
    <et-footer></et-footer>
  </div>
</template>

<script>
import BulbousShapeLink from './BulbousShapeLink.vue';
import FeatureVideo from './FeatureVideo.vue';
import Lottie from "./Lottie.vue";
import ScallopedShapeLink from './ScallopedShapeLink.vue';
import WavyShapeLink from './WavyShapeLink.vue';
import DraftDoodle from './DraftDoodle.vue';
import ExampleDoodle from './ExamplesDoodle.vue'
import FeedbackDoodle from './FeedbackDoodle.vue';
import HeroHeader from './HeroHeader.vue';

export default {
  components: { 
    Lottie, 
    FeatureVideo, 
    WavyShapeLink, 
    BulbousShapeLink, 
    ScallopedShapeLink, 
    DraftDoodle, 
    ExampleDoodle, 
    FeedbackDoodle,
    HeroHeader
  },

  data() {
    return {
      darkHeader: true,
      shapePosition: {
        transform: '',
      },
    };
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },

  methods: {
    handleMouseMove(event) {
      this.shapePosition.transform = 'translate(' + event.clientX / '100' + '%,' + event.clientY / '100' + '%) rotate('  + event.clientY / '100' + 'deg';
    },

    handleMouseLeave() {
      this.shapePosition.transform = 'translate(0, 0) rotate(0)';
    },

    handleScroll() {
      const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;

      if (scrollPosition > 100) {
        this.darkHeader = false;
      } else {
        this.darkHeader = true;
      }
    },

    scrollToTags() {
      this.$refs.tags.scrollIntoView({
        behavior: 'smooth'
      });
    },

    scrollToExamples() {
      this.$refs.examples.scrollIntoView({
        behavior: 'smooth'
      });
    },

    scrollToFeedback() {
      this.$refs.feedback.scrollIntoView({
        behavior: 'smooth'
      });
    },
  }
};
</script>
