<template>
  <div ref="container" class="container" @focusout="onFocusout" @keyup.esc="closeMenu">
    <button
      ref="button"
      class="button"
      @click="toggleMenu"
      :aria-expanded="isOpen.toString()"
      :aria-controls="menuId"
      :disabled="disabled"
    >
      {{ label }} <i class="fas fa-caret-down"></i>
    </button>
    <ul :id="menuId" :hidden="!isOpen" class="menu up right slim" @click="toggleMenu">
      <slot name="items"></slot>
    </ul>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    menuId: {
      type: String,
      default: "finalize-menu-items",
    },
  },
  setup() {
    const container = ref(null);
    const button = ref(null);
    const isOpen = ref(false);
    const toggleMenu = () => (isOpen.value = !isOpen.value);
    const closeMenu = () => {
      isOpen.value = false;
      button.value.focus();
    };
    const onFocusout = (event) => {
      if (!container.value.contains(event.relatedTarget)) {
        isOpen.value = false;
      }
    };

    return {
      container,
      button,
      isOpen,
      toggleMenu,
      closeMenu,
      onFocusout,
    };
  },
});
</script>

<style scoped>
.container {
  position: relative;
  z-index: 4;
}

.fas {
  transition: all 0.5s ease;
  margin-left: 10px;
}

.menu {
  position: absolute;
  background: white;
  border: 1px solid var(--grey-4);
  border-radius: 5px;
  list-style: none;
  padding: 12px 0;
  width: 300px;
  z-index: 1000;
  top: 42px;
  font-size: 0.8em;
  line-height: 1.7em;
  letter-spacing: 0.02em;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.08);
}

.left {
  left: -24px;
}

.right {
  right: -8px;
}

.slim {
  width: 200px;
}

.wide {
  width: 480px;
}

.up {
  bottom: 40px;
  top: auto;
}
</style>
