<template>
  <ls-modal @close="$emit('close')" :visible="true">
    <template #content>
      <h2 id="dialog_label" class="text-med">Admin Mode Details</h2>
      <div class="modal-body viewing-mode-modal">
        <p>
          This mode is ideal if you are using Lettersmith to help learners improve their writing. 
          You will have the ability to join and be invited to templates, as well as create and invite users to your own templates.
        </p>
        <p>
          It is suggested that you select this mode if you are a…
        </p>
        <ul class="no-list-styling">
          <li>Teacher</li>
          <li>Counselor</li>
          <li>Facilitator</li>
          <li>Group Leader</li>
          <li>Or anyone with a similar sounding role!</li>
        </ul>
        <p>
          If you are stuck not knowing which mode you should choose, 
          please reach out to us at <a href="mailto:lettersmith-support@umich.edu">lettersmith-support@umich.edu</a> so we can help guide you towards the right choice.
        </p>
      </div>
    </template>
  </ls-modal>
</template>

<script>
import Modal from "shared/components/Modal.vue";

export default {
  components: {
    "ls-modal": Modal,
  },
};
</script>
