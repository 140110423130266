<template>
  <ls-modal @close="$emit('close')" :visible="true">
    <template #content>
      <h2 id="dialog_label" class="text-med">Viewing Mode Details</h2>
      <div class="modal-body viewing-mode-modal">
        <p>
          Lettersmith has two distinct viewing modes: <b>Writer</b> & <b>Admin</b>. 
        </p>
        <p>
          By choosing the mode that makes the most sense for <b>you</b> specifically, we will be able to show you the information that will benefit you the most throughout your Lettersmith journey!
        </p>
        <p>
          If you have any questions about this, 
          please reach out to us at <a href="mailto:lettersmith-support@umich.edu">lettersmith-support@umich.edu</a>.
        </p>
      </div>
    </template>
  </ls-modal>
</template>

<script>
import Modal from "shared/components/Modal.vue";

export default {
  components: {
    "ls-modal": Modal,
  },
};
</script>
