var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"template-section"},[_c('section',{staticClass:"your-templates"},[_c('div',{staticClass:"dashboard-header"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"buttons"},[_c('button',{ref:"joinTemplateButton",staticClass:"button-secondary",attrs:{"aria-haspopup":"dialog"},on:{"click":function($event){_vm.showJoinTemplateModal = true}}},[_vm._v("\n          Join Template\n        ")]),_vm._v(" "),(!_vm.user.is_writer)?_c('button',{ref:"createTemplateButton",staticClass:"button",attrs:{"aria-haspopup":"dialog"},on:{"click":function($event){_vm.showCreateTemplateModal = true}}},[_vm._v("\n          Create Template\n        ")]):_vm._e()])]),_vm._v(" "),(_vm.myTemplates.length > 0)?_c('ul',{staticClass:"all-templates-list all-templates",attrs:{"aria-labelledby":"templates-heading"}},_vm._l((_vm.myTemplates),function(template){return _c('template-card',{key:template.id,attrs:{"template":template}})}),1):_c('div',{staticClass:"empty-state"},[_c('img',{staticClass:"empty-image",attrs:{"alt":"","src":"/static/images/empty-states/empty-examples.png"}}),_vm._v(" "),_c('p',{staticClass:"text-med serif"},[_vm._v("No Templates Found!")]),_vm._v(" "),_vm._m(1),_vm._v(" "),_vm._m(2)]),_vm._v(" "),(_vm.showCreateTemplateModal)?_c('template-modal-create',{on:{"close":function($event){_vm.showCreateTemplateModal = false;
        _vm.$refs.createTemplateButton.focus();},"created-template":_vm.handleNewTemplateCompletion}}):_vm._e(),_vm._v(" "),(_vm.showJoinTemplateModal)?_c('template-modal-join',{on:{"close":function($event){_vm.showJoinTemplateModal = false;
        _vm.$refs.joinTemplateButton.focus();},"joined-template":_vm.handleJoinedTemplate}}):_vm._e()],1),_vm._v(" "),_c('section',{staticClass:"public-templates"},[_vm._m(3),_vm._v(" "),(_vm.publicTemplates.length > 0)?_c('ul',{staticClass:"all-templates-list all-public-templates",attrs:{"aria-labelledby":"templates-heading"}},_vm._l((_vm.publicTemplates),function(template){return _c('li',{key:template.id,staticClass:"template-card"},[_c('public-template-card',{attrs:{"template":template}})],1)}),0):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex"},[_c('h2',{staticClass:"text-med serif",attrs:{"id":"templates-heading"}},[_vm._v("My Templates")]),_vm._v(" "),_c('p',{staticClass:"text-small"},[_vm._v("\n          Templates help provide support to writers in the drafting stage of the writing process.\n        ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("If you are an "),_c('b',[_vm._v("instructor,")]),_vm._v(" create templates to help guide your writers as they create their draft.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("If you are a "),_c('b',[_vm._v("student,")]),_vm._v(" ask your instructor for an invite through a code, link, or email. Typically, you won’t be creating templates yourself as a student.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard-header"},[_c('div',{staticClass:"flex"},[_c('h2',{staticClass:"text-med serif",attrs:{"id":"templates-heading"}},[_vm._v("Public Templates")])])])
}]

export { render, staticRenderFns }