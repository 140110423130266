<template>
  <main id="main" class="draft-view-container" v-if="draft">
    <finalize-modal-submit-submission
      :visible="true"
      :original-email="draft"
      @completed="completeSubmission"
      @close="goBackToDrafting"
    ></finalize-modal-submit-submission>
  </main>
</template>

<script>
import { computed } from "vue";

import { useRouter } from "shared/composables/useRouter";
import { useStore } from "shared/composables/useStore";

import FinalizeModalSubmitSubmission from "./FinalizeModalSubmitSubmission.vue";

export default {
  name: "FinalizeSubmitView",

  components: {
    FinalizeModalSubmitSubmission,
  },

  setup() {
    const router = useRouter();
    const store = useStore();

    const draft = computed(() => store.state.drafting.currentEmail);

    const goBackToDrafting = () => {
      router.push({
        name: "Draft",
        params: { templateId: draft.value.template_id, slug: draft.value.slug },
      });
    };

    const completeSubmission = () => {
      store.dispatch("showSnackbar", "Submission submitted successfully");
      goBackToDrafting();
    };

    return {
      draft,
      goBackToDrafting,
      completeSubmission,
    };
  },
};
</script>

<style lang="scss" scoped></style>
